.membersCard{
    height: 280px;
    min-width: 150px;
    position: relative;
    .membersThumbnail{
        width: 100%;
        height: 100%;
        transition: .5s ease;
        object-fit: cover;
    }
    .name{
        position: absolute;
        bottom: 0;
        left: 20px;
        z-index: 2;
    }
    .overlayDark {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(52,52,51,0.7);
        overflow: hidden;
        width: 100%;
        height: 100%;
        transition: .5s ease;
    }
    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #efefef;
        overflow: hidden;
        width: 100%;
        height: 0;
        transition: .5s ease;
        .content{
            padding: 10px;
            h4{
                margin-bottom: 10px;
            }
        }
      }
      
      &:hover {
        .overlay {
            height: 80px;
        }
        .overlayDark{
            background-color: transparent;
            transition: .8s ease;
        }
        .membersThumbnail{
            height: 90%;
            transition: .5s ease;
        }
        .name{
            display: none;
        }

    }
}