.skillsListDropdown{
    border-bottom: 1px solid $primaryColor;
    padding: 15px;
    position: relative;
    cursor: pointer;
    .chevron{
        width: 10px;
        object-fit: contain;
        
    }
    .rotated{
        transform: rotate(90deg);
    }
    .label{
        font-size: 16px;
        font-weight: 600;
    }
    .skillsList{
        position: absolute;
        overflow: scroll;
        right: 0;
        background-color: white;
        top: 100%;
        width: 200px;
        z-index: 2;
        padding: 10px;
        .list{
            max-height: 150px;
            
            list-style: none;
            padding: 0;
            .listItem{
                font-size: 13px;
                font-weight: 400;
                padding: 5px 0 5px 0;
            }
        }
    }
}