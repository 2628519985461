.membersList{
    .holder{
        grid-template-columns: 95%;
        padding-bottom: 0px;
        &.noPadding{
            padding: 0px
        }
    }
    .first{
        margin-right: 40px;
        @media screen and (max-width: 768px) {
            margin-right: 18px;
            font-size: 16px;
        }
    }
    .active{
        background-color: $primaryColor;
        color: white;
        padding: 5px;
    }
}