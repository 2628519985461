.serviceProviders{
    justify-items: center;
    grid-column-gap: 50px;
    align-items: center;
    row-gap: 50px;
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, auto);
        justify-items: center;
        a{
            display: flex;
            justify-content: center;
            width: 80%;
        }
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, auto);
    }
    .providerLogo{
        width: 100%;
        object-fit: cover;
        @media screen and (max-width: 1024px) {
            width: 80%;

        }
    }
}