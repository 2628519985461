.nav-panel {
    height: 100vh;
    min-width: 320px;
    width: 45%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.mainPanelLink {
    font-family: "Montserrat";
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    position: relative;
    cursor: pointer;
}

.activePanelLink {}