.profilePic {
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.meetingRequestContainer {
    display: flex;
    flex-direction: column;
}

.ProfilePicContainer {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.meetingFormContainer {
    display: flex;
    flex-direction: column;
    width: 30vw;
    margin: 10px;
    margin-top: 0px;
}

.meetingContentContainer {
    display: flex;
    flex-direction: row;
}

.meetingRequestContainerSuccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}