.contactUs{
    padding-top: 150px !important;
    .top{
        border-bottom: solid 1px $primaryColor;
        .left{
            div{
                margin-bottom: 20px;
                img{
                    width: 30px;
                    object-fit: contain;
                    margin-right: 30px;
                }
                p{
                    font-size: 24px !important;
                    @media screen and (max-width: 768px) {
                        font-size: 16px !important;
                    }
                }
            }
        }
    }
}