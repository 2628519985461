.blogArchive {
  .blogArchiveGrid {
    row-gap: 30px;
    .content {
      display: grid;
      grid-template-columns: 40% 60%;
      align-items: center;
      @media screen and (max-width: 900px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .contentHeading {
        margin-bottom: 0px;
        @media screen and (max-width: 900px) {
          text-align: center;
        }
      }
      &:nth-child(2) {
        direction: ltr;
        text-align: left;
        @media screen and (max-width: 900px) {
          text-align: center;
          direction: unset;
        }
        .contentLeft {
          padding: 0px 70px 0px 0px;
          @media screen and (max-width: 900px) {
            padding: 0px;
          }
        }
        .button {
          display: block !important;
          direction: ltr;
          margin-top: 30px;
        }
      }
      .contentLeft {
        @media screen and (max-width: 900px) {
          padding: 0px;
        }
        .button {
          display: none;
        }
      }

      .contentImage {
        height: 400px;
        width: 400px;
        object-fit: contain;
        @media screen and (max-width: 900px) {
          width: 100%;
          object-fit: fill;
        }
      }
    }
  }
}
