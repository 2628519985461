.knock-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background-color: #efefef;
    .profile{
        display: flex;
        width: 60px;
        height: 60px;
        border-radius:10px;
        justify-content: center;
        align-items: center;
        img {
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    .activity{

    }
    .title-section{
        display: flex;
        flex-direction: column;
        // white-space: nowrap;
        font-size: 12px;
    }
}

.blogDate {
    margin-top: 0px;
    font-size: 12px;
    color: rgb(49, 75, 91);
}

.name-size {
    font-size: 19px;
}

.reply-btn {
    width: 100%;
    display: flex;
}

.bodyMain {
    margin-left: 50px;
    margin-bottom: 50px;  
}

.side-by-side {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.side-by-side-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    @media screen and (max-width: 540px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.start-section {
    width: 15% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        @media screen and (max-width: 768px) {
            width: 34px !important;
            height: 34px;
            margin-right: 6px;
        }
    }
}

.end-section {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px) {
        width: 100% !important;
        font-size: 12px;
    }
}

.top-header-title {
    width: 100%;
    border-bottom: 1px solid #2A4E60;
}

.no-pad {
    padding: 0px;
    margin: 0px;
}

.text-body {
    padding-top: 5px;
    color: #2A4E60;
    font-weight: 200;
    font-size: 13x;
}

.comment-box {
    background-color: rgb(213, 208, 208);
    width: 100%;
    display: flex;
    padding: 5px;
}

.comment-section {
    padding-top: 1.2em;
}

.comment-header {
    display: flex;
    justify-content: space-between;
}

.poster {
    font-size: 18px;
    font-weight: 200;
    color: rgb(49, 75, 91);
}

.full-width {
    width: 100%;
}

.reply-button-section {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 1.2em;
    min-height: 2px;
    margin-top: 12px;
    @media screen and (max-width: 768px) {
        justify-content: center;
        width: 100%;
        margin-top: 12px;
    }
    > div {
        width: 100% !important;
        justify-content: flex-end;
        @media screen and (max-width: 768px) {
            justify-content: center;
        }
    }
}

.jc-c {
    justify-content: center;
}

.jc-sb {
    justify-content: space-between;
}

.flexCol {
    display: flex;
    flex-direction: column;
}

.knock-dd {
    background-color: transparent !important;
    border: 1px solid #2A4E60 !important;
    padding: 13px 5px !important;
    font-size: 16px !important;
    margin-bottom: 10px !important;
}