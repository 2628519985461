.knock-card {
    width: 100%;
    display: flex;
    margin: 18px 0;
}

.knock-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.profile{
    display: flex;
    width: 60px;
    height: 60px;
    border-radius:10px;
    justify-content: center;
    align-items: center;
    img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
    }
}
.owner{
    color: #2A4E60;
}
.title-section{
    display: flex;
    flex-direction: column;
    // white-space: nowrap;
    font-size: 12px;
    margin-left: 1.2em;
}

.blogDate {
    margin-top: 0px;
    font-size: 12px;
    color: rgb(211, 211, 211);
    text-transform: uppercase;
}

.name-size {
    font-size: 20px !important;
    text-decoration: none;
}

.reply-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.body-section {
    display: flex;
    padding-top: 0px;
    margin-top: 0px;
    padding-left: 10px;
    width: 54.5%;
    color: #2A4E60;
    font-weight: 200;
    font-size: 16px;
    text-align: left;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.history-header {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #2A4E60;
    justify-content: space-between;
    padding: 0px;
    margin-bottom: 2em;
}

.no-pad {
    padding: 0px;
    margin: 0px;
}

.header-btn {
    display: flex;
    align-items: center;
    padding-bottom: 0.2em;
    justify-content: flex-end;
}

.start-sections {
    display: flex;
    width: 20%;
    min-width: 100px;
    @media screen and (max-width: 768px) {
        width: 60%;
    }
}

.reply-btn {
    width: 10.3% !important;
}

.text-center {
    text-align: center;
}

.m-top {
    margin-top: 0.6em !important;
}


.replyBtnContainer{
    position: relative;
    display: flex;
    justify-content: center;
}

.replyBtn{
    padding: 15px 15px 15px 15px;
    border: solid 1px white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-size: 13px;
    background-color: $primaryColor;
    // min-width: 200px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    &:hover{
        background-color: $secondaryColor;
    }
    &:after{
        content:'';
        background: url('../../../assets/images/arrowRight.png'); 
        background-repeat: no-repeat;
        object-fit: contain;
        background-size: 11px;
        display: block;
        height:11px;
        width: 11px;
        margin-left: 10px;
    }
}