.typists{
    .card{
        padding-top: 20px;
        @media screen and (max-width: 1024px) {
            padding: 10px 20px;
        }
        h4{
            margin-top: 0;
            margin-bottom: 10px;
        }
        p{
            margin-bottom: 7px;
        }
        .typistLogo{
            width: 90px;
            height: 90px;
            border-radius: 50px;
            object-fit: cover;
            margin-right: 20px;
            background-color: grey;
            @media screen and (max-width: 1024px) {
                width: 70px;
                height: 70px;
            }
        }
        .user{
            grid-template-columns: 30% 70%;
            padding-top: 10px;
        }
    }
}