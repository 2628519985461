.commonRoomCard{
    grid-row-gap: 10px;
    .top{
        grid-template-columns: 70% 30%;
        align-items: flex-end;
        .roomIcon{
            width: 50px;
            height: 50px;
            margin-left: auto ;
            object-fit: contain;
        }
        .roomHeading{
            margin: 15px 0px 10px 0;
        }
        .roomDescription{
            font-size: 12px;
        }
    }
    .joinBtn{
        padding: 5px 10px;
        border: solid 1px white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Montserrat';
        text-transform: uppercase;
        font-size: 13px;
        background-color: #2A4E60;
        display: flex;
        justify-content: center;
        font-weight: 600;
        a{
            text-decoration: none;
            color: white;
        }
        &:after {
            content: '';
            background: url('../../../assets/images/arrowRight.png');
            background-repeat: no-repeat;
            object-fit: contain;
            background-size: 11px;
            display: block;
            height: 11px;
            width: 11px;
            margin-left: 10px;
        }
    }
    .users{
        grid-row-gap: 3px;
        grid-column-gap: 5px;
        
    }
}