.label {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
}

.profilePageContainer {
    padding: 50px 0px;
    justify-content: center;
    position: relative;
    padding-top: 100px;
    width: 100%;
    min-width: 280px;
    max-width: 800px;
    margin: 0 auto;
}

.profilePage {
    width: 100%;
}

.profileImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .profileImage {
        width: 220px;
        height: 230px;
        border-radius: 100%;
        object-fit: cover;
        position: relative;
    }

    .uploadContainer {
        position: absolute;
        width: fit-content;
        height: fit-content;
        padding: 0;
        margin: 0;
    }

    .uploadButton {
        padding: 0.8em;
        margin: 2;
        border-radius: 0%;
    }

    .fileContainer {
        align-content: unset;
        align-items: unset;
        align-self: unset;
        animation: unset;
        appearance: unset;
        backface-visibility: unset;
        background-blend-mode: unset;
        background: unset;
        binding: unset;
        block-size: unset;
        border-block-end: unset;
        border-block-start: unset;
        border-collapse: unset;
        border-inline-end: unset;
        border-inline-start: unset;
        border-radius: unset;
        border-spacing: unset;
        border: unset;
        bottom: unset;
        box-align: unset;
        box-decoration-break: unset;
        box-direction: unset;
        box-flex: unset;
        box-ordinal-group: unset;
        box-orient: unset;
        box-pack: unset;
        box-shadow: unset;
        box-sizing: unset;
        caption-side: unset;
        clear: unset;
        clip-path: unset;
        clip-rule: unset;
        clip: unset;
        color-adjust: unset;
        color-interpolation-filters: unset;
        color-interpolation: unset;
        color: unset;
        column-fill: unset;
        column-gap: unset;
        column-rule: unset;
        columns: unset;
        content: unset;
        control-character-visibility: unset;
        counter-increment: unset;
        counter-reset: unset;
        cursor: unset;
        display: unset;
        dominant-baseline: unset;
        empty-cells: unset;
        fill-opacity: unset;
        fill-rule: unset;
        fill: unset;
        filter: unset;
        flex-flow: unset;
        flex: unset;
        float-edge: unset;
        float: unset;
        flood-color: unset;
        flood-opacity: unset;
        font-family: unset;
        font-feature-settings: unset;
        font-kerning: unset;
        font-language-override: unset;
        font-size-adjust: unset;
        font-size: unset;
        font-stretch: unset;
        font-style: oblique;
        font-synthesis: unset;
        font-variant: unset;
        font-weight: unset;
        force-broken-image-icon: unset;
        height: unset;
        hyphens: unset;
        image-orientation: unset;
        image-region: unset;
        image-rendering: unset;
        ime-mode: unset;
        inline-size: unset;
        isolation: unset;
        justify-content: unset;
        justify-items: unset;
        justify-self: unset;
        left: unset;
        letter-spacing: unset;
        lighting-color: unset;
        line-height: unset;
        list-style: unset;
        margin-block-end: unset;
        margin-block-start: unset;
        margin-inline-end: unset;
        margin-inline-start: unset;
        margin: unset;
        marker-offset: unset;
        marker: unset;
        mask-type: unset;
        mask: unset;
        max-block-size: unset;
        max-height: unset;
        max-inline-size: unset;
        max-width: unset;
        min-block-size: unset;
        min-height: unset;
        min-inline-size: unset;
        min-width: unset;
        mix-blend-mode: unset;
        object-fit: unset;
        object-position: unset;
        offset-block-end: unset;
        offset-block-start: unset;
        offset-inline-end: unset;
        offset-inline-start: unset;
        opacity: unset;
        order: unset;
        orient: unset;
        outline-offset: unset;
        outline-radius: unset;
        outline: unset;
        overflow: unset;
        padding-block-end: unset;
        padding-block-start: unset;
        padding-inline-end: unset;
        padding-inline-start: unset;
        padding: unset;
        page-break-after: unset;
        page-break-before: unset;
        page-break-inside: unset;
        paint-order: unset;
        perspective-origin: unset;
        perspective: unset;
        pointer-events: unset;
        position: unset;
        quotes: unset;
        resize: unset;
        right: unset;
        ruby-align: unset;
        ruby-position: unset;
        scroll-behavior: unset;
        scroll-snap-coordinate: unset;
        scroll-snap-destination: unset;
        scroll-snap-points-x: unset;
        scroll-snap-points-y: unset;
        scroll-snap-type: unset;
        shape-rendering: unset;
        stack-sizing: unset;
        stop-color: unset;
        stop-opacity: unset;
        stroke-dasharray: unset;
        stroke-dashoffset: unset;
        stroke-linecap: unset;
        stroke-linejoin: unset;
        stroke-miterlimit: unset;
        stroke-opacity: unset;
        stroke-width: unset;
        stroke: unset;
        tab-size: unset;
        table-layout: unset;
        text-align-last: unset;
        text-align: unset;
        text-anchor: unset;
        text-combine-upright: unset;
        text-decoration: unset;
        text-emphasis-position: unset;
        text-emphasis: unset;
        text-indent: unset;
        text-orientation: unset;
        text-overflow: unset;
        text-rendering: unset;
        text-shadow: unset;
        text-size-adjust: unset;
        text-transform: unset;
        top: unset;
        transform-origin: unset;
        transform-style: unset;
        transform: unset;
        transition: unset;
        user-focus: unset;
        user-input: unset;
        user-modify: unset;
        user-select: unset;
        vector-effect: unset;
        vertical-align: unset;
        visibility: unset;
        white-space: unset;
        width: unset;
        will-change: unset;
        window-dragging: unset;
        word-break: unset;
        word-spacing: unset;
        word-wrap: unset;
        writing-mode: unset;
        z-index: unset;
    }
}

.updatePayment{
    cursor: pointer;
}

.multi_select {
    color: black !important;
    background-color: transparent !important;
      border: 1px solid #2A4E60 !important;
      padding: 10px 5px !important;
      font-size: 16px !important;
      margin-bottom: 10px !important;
      --rmsc-bg: #EFEFEF !important;
  }