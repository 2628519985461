.signUpModal {
  .logo{
    display: flex;
    margin: 0 auto;
    width: 250px;
  }
  .signInLogo{
    width: 80%;
    object-fit: contain;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  margin: 20px;
  margin-top: 6px;
  .close {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 25px;
    height: 25px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before,
  .close:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 25px;
    width: 2px;
    background-color: #1f224a;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  .signUpContent {
    grid-template-rows: 420px;
    .signIn {
      grid-auto-rows: max-content;
    }
    @media screen and (max-width: 1024px) {
      grid-template-rows: auto;
    }
  }
  .acceptTos {
    color: black !important;
  }
  a {
    text-decoration: inherit;
  }
  li{
    color: $primaryColor;
    padding: 7px;
    font-family: 'Montserrat';
  }

  .errorMessage{
    color: red;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: x-small;
  }
}

.error {
  color: rgb(231, 40, 40);
  font-size: 0.7em;
  padding-bottom: 1em;
}

.multi_select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: transparent !important;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 20px; /* Height */
}

li{
  color: $primaryColor;
  padding: 7px;
  font-family: 'Montserrat';
}

.center-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.forgotPassword{
  margin-top: 20px;
  cursor: pointer;
}
.paymentContent {
  margin-bottom: 30px;
  width: 80%;
  > content > img {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}

.paymentImg {
    @media screen and (max-width: 1024px) {
      max-width: 400px;
    }
    @media screen and (max-width: 768px) {
      max-width: 280px;
    }
}

.m-0 {
  margin: 0 !important;
}

.mb-1 {
  margin-bottom: 38px;
}

.mt-0 {
  margin-top: 0;
}

.pt-1 {
  padding-top: 12px;
}

.signUpProsList {
  padding-left: 0;
  li {
    padding-left: 0;
  }
}

.signUpStepTwo {
  width: 100%;
  
}

.signUpTop {
    background-color: transparent;
    border: solid 1px white;
    color: white;
    font-family: 'Montserrat';
    font-weight: 600;
    white-space: nowrap;
    font-size: 16px;
    height: 35px;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      padding-left: 20px;
    }
}

.resp-g-t-r {
  grid-template-rows: auto !important;
  @media screen and (max-width: 1024px) {
    grid-template-rows: 420px !important;
  }
  @media screen and (max-width: 768px) {
    grid-template-rows: 400px !important;
  }
}
