
.defaultBtnContainer{
    position: relative;
    display: flex;
    justify-content: center;
}

.defaultBtn{
    padding: 15px 10px 15px 10px;
    border: solid 1px white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-size: 13px;
    background-color: $primaryColor;
    min-width: 200px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    &:hover{
        background-color: $secondaryColor;
    }
    &:after{
        content:'';
        background: url('../../../assets/images/arrowRight.png'); 
        background-repeat: no-repeat;
        object-fit: contain;
        background-size: 11px;
        display: block;
        height:11px;
        width: 11px;
        margin-left: 10px;
    }
}