
.secondaryButtonContainer{
    position: relative;
    .secondaryBtn{
        padding: 10px 10px 15px 10px;
        border: solid 1px white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Montserrat';
        text-transform: uppercase;
        font-size: 13px;
        background-color: $secondaryColor;
        min-width: 180px;
        display: flex;
        justify-content: center;
        font-weight: 600;
        margin-top: 15px;
        color: white;
        &:hover{
            background-color: $secondaryColor;
        }
    }
}
