.ourStory{
    .card{
        margin-top: 50px;
    }
    .founderImg{
        width: 100%;
        object-fit: contain;
        margin-top: 20px;
        border-radius: 20px;
    }
    p{
        font-size: 15px;
    }
}