$primaryColor:#2A4E60;
$secondaryColor: #1F224A;
$grey: #efefef;

[data-reactroot] 
        {height: 100% !important; }
  

body{
    background-color: #EFEFEF;
    position: relative;
    min-height: 65vh;
    padding-bottom: 500px;
    @media screen and (max-width: 768px) {
        padding-bottom: 900px;
    }

}

input{
    font-family: 'Montserrat';
}

textarea{
    font-family: 'Montserrat';
}

.center{
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
}

.tcenter{
    text-align: center;
}

.paragraph{
    text-align:justify;
    text-justify:inter-word;
}

.one{
    display: grid;
    grid-template-columns: 1fr;
}

.two{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.three{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 540px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.four{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.five{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.six{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 540px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.grg10{
    grid-row-gap: 10px;
}

.grg20{
    grid-row-gap: 20px;
}

.grg30{
    grid-row-gap: 30px;
}

.gcg10{
    grid-column-gap: 10px;
}

.gcg20{
    grid-column-gap: 20px;
}

.gcg30{
    grid-column-gap: 30px;
}

.white{
    color: white;
}

.primary{
    color: $primaryColor;
}

.secondary{
    color: $secondaryColor;
}

.bold{
    font-weight: 700;
}

.italic{
    font-style: italic;
}

.semi-bold{
    font-weight: 600;
}

.medium{
    font-weight: 500;
}

.light{
    font-weight: 400;
}
h1, h2, h3, h4, a, li{
    font-family: 'Montserrat';
    font-weight: 600;
    position: relative;
    &.line{
        width: max-content;
        @media screen and (max-width: 800px){
            width: auto;
        }
    }
    &.line::after{
        content: '';
        width: 100%;
        display: block;
        margin-top: 5px;
        height: 1px;
        background-color: $primaryColor;
    }
}

.marginTopBottom{
    margin:30px 0px;
}
.noMargin{
    margin: 0px;
}

.noMarginBottom{
    margin-bottom: 0;
}

.uppercase{
    text-transform: uppercase;
}

p{
    font-weight: 300;
    font-family: 'Montserrat';
    font-size: 14px;
    margin: 0;
    &.large{
        font-size: 20px;
        font-weight: 400;
    }
}

.holder{
    display: grid;
    padding: 50px 0px;
    justify-content: center;
    grid-template-columns: 80%;
    position: relative;
    &.top{
        padding-top: 150px;
    }
    &.small{
        width: 75%;
        margin: 0 auto;
    }
}

.noLinkDeco{
    text-decoration: none;
}

.holderBasic{
    flex: 0.95;
    margin: 0 auto;
}

.flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.jb-fs-sb {
    justify-content: flex-start;
    @media screen and (max-width: 540px) {
        justify-content: space-between;
    }
}

.spaceBetween{
    justify-content: space-between;
}

.relative{
    position: relative;
}

.defaultInput { 
    background-color: transparent !important;
    border: 1px solid $primaryColor !important;
    padding: 10px 5px !important;
    font-size: 16px !important;
    margin-bottom: 10px !important; 
}

.passwordInput { 
    display: flex;
    flex-direction: column;
    position: relative;
}

.showPassword{
    position: absolute;
    right: 20px;
    font-weight: 600;
    top: 20%;
    color: $secondaryColor;
    cursor: pointer;
}

input[type=date] {
    height: 18px;
    margin: 0 auto;
    width: 98%;
    font-size: 18px;
    text-transform: uppercase;
    outline: none;
    border: 0;
    padding: 0 3px;
    color: black;
    
    &::-webkit-clear-button {
      font-size: 18px;
      position: relative;
      right: 5px;
      margin-right: 4px;
    }
    &::-webkit-calendar-picker-indicator {
      font-size: 18px;
      
    }
  }
  

.defaultSearch { 
    background-color: white;
    border: 1px solid $primaryColor;
    padding: 10px 5px;
    max-width: 300px ;
    font-size: 16px;
    padding-left: 50px;
    &::placeholder{
        color: $primaryColor;
    }
}

.searchIconBlue::before {
    content: '';
    background: url('../assets/images/searchIconBlue.png'); 
    background-repeat: no-repeat;
    object-fit: contain;
    background-size: 20px;
    display: block;
    height:20px;
    width: 32px;
 }

.defaultTextarea{
        background-color: transparent;
        border: 1px solid $primaryColor;
        padding: 10px 5px;
        font-size: 16px;
        margin-bottom: 10px;
        height: 100px;
}

.defaultSelect{
    padding: 5px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid $primaryColor;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: $primaryColor;
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.dashboardContainer{
    background-color: white;
    padding: 0px 20px 20px 20px;
    border-radius: 20px;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0, 0.16);
    margin-bottom: 30px;
}
.card{
    background-color: white;
    padding: 0px 20px 20px 20px;
    border-radius: 20px;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0, 0.16);
    margin-bottom: 30px;
}
.break{
    height: 1px;
    width: 100%;
    background-color: $primaryColor;
}

.verticalBreak{
    height: 100%;
    width: 1px;
    background-color: $primaryColor;
}

