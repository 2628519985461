.customModal{
    .welcomeLogo{
        height: 50px;
        display: block;
        margin: 0 auto;
    }
    p{
        margin-bottom: 20px;
    }
    .close {
        position: absolute;
        right: 25px;
        top: 25px;
        width: 25px;
        height: 25px;
        opacity: 0.3;
      }
      .close:hover {
        opacity: 1;
      }
      .close:before,
      .close:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 25px;
        width: 2px;
        background-color: #1f224a;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }
}