.courtLinks{
    row-gap: 20px;
    .top{
        justify-content: space-between;
    }
    .currentLectures{
        grid-column-gap: 40px;
        grid-template-columns: repeat(3, auto);
        @media screen and (max-width: 1024px) {
            grid-template-columns: repeat(2, auto);
        }
        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(1, auto);
        }
        .verticalBreak{
            height: 100%;
            width: 1px;
            background-color: $primaryColor;
        }
        .card{
            grid-template-columns: 35% 65%;
            
            p{
                font-size: 16px;
            }
            .profilePicture{
                height: 100px;
                width: 100px;
                border-radius: 50px;
                object-fit: cover;
                margin-left: 10px;
                box-shadow: 0px 3px 6px 0px rgba(0,0,0, 0.16);
            }
            .cardTop{
                grid-column: 1/3;
            }
            .cardBottom{
                grid-column: 1/3;
                h3{
                    margin-top: 5px;
                }
            }
        }
    }
    .listItem{
        font-size: 13px;
        font-weight: 400;
        padding: 5px 0 5px 0;
    }
    .skillsListDropdown{
        border-bottom: 1px solid $primaryColor;
        padding: 15px;
        position: relative;
        .chevron{
            width: 10px;
            object-fit: contain;
            
        }
        .rotated{
            transform: rotate(90deg);
        }
        .label{
            font-size: 16px;
            font-weight: 600;
        }
        .skillsList{
            position: absolute;
            overflow: scroll;
            right: 0;
            background-color: white;
            top: 100%;
            width: 200px;
            z-index: 2;
            padding: 10px;
            .list{
                max-height: 150px;
                
                list-style: none;
                padding: 0;
                .listItem{
                    font-size: 13px;
                    font-weight: 400;
                    padding: 5px 0 5px 0;
                }
            }
        }
    }
}