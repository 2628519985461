

.profilePage {
  margin-top: 50px;
  .one{
    @media screen and (max-width: 900px){
        justify-content: center;
    }
  }
  .blueCard {
    background-color: #2a4e60;
    padding: 15px 20px;
    border-radius: 20px;
  }
  .infoRight {
    .iconHeading {
      flex-wrap: nowrap;
      justify-items: center;

      h4 {
        position: relative;
        margin-bottom: 10px;
        &::after {
          content: "";
          height: 1px;
          bottom: -5px;
          left: 0;
          width: 100%;
          background-color: white;
          position: absolute;
        }
      }
    }
  }
  .icon {
    width: 20px;
    height: 100%;
    object-fit: contain;
    margin-right: 10px;
  }
  .personalInformationContent {
    border-radius: 0px 20px 20px 0px;
    padding: 0px 20px;
    background-color: #2a4e60;
    grid-template-columns: 35% 65%;
    @media screen and (max-width: 900px){
        grid-template-columns: 100%;
        border-radius: 20px 20px 20px 20px;
        padding-bottom: 20px;
    }

    .personalInfo {
      h3 {
        margin-bottom: 20px;
      }
      .flexRow {
        margin-bottom: 10px;
      }
    }
    .right {
      margin-top: 20px;
    }
  }
  .top {
    grid-template-columns: 30% 70%;
    @media screen and (max-width: 900px){
        grid-template-columns: 100%;
    }
    .verticalBreak {
      height: 100%;
      width: 1px;
      background-color: $primaryColor;
    }
    .profileImage {
      width: 100%;
      height: 50vh;
      object-fit: cover;
      border-radius: 20px 0px 20px 20px;
      @media screen and (max-width: 900px) {
        border-radius: 20px;
      }
    }

    .iconHeading {
      .icon {
        width: 35px;
        height: 30px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
    .skillSet {
      align-content: start;
      @media screen and (max-width: 900px) {
        margin-bottom: 20px;
    }
      .iconHeading {
        position: relative;
        @media screen and (max-width: 350px) {
          margin-left: 14px;
        }
        &::after {
          content: "";
          height: 2px;
          bottom: 0;
          left: 45px;
          width: 20%;
          background-color: white;
          position: absolute;
        }
        .icon {
          height: 30px;
          object-fit: contain;
          margin-right: 10px;
        }
      }
      .skillList {
        list-style: none;
        padding-left: 10px;
        columns: 3;
        @media screen and (max-width: 630px) {
          columns: 2;
          font-size: 13px;
        }
      }
    }
  }
  .bottom {
    margin-left: 20px;
    @media screen and (max-width: 900px){
        grid-template-columns: 100%;
        margin: 0;
    }
  }
}

.one-resp {
  @media screen and (max-width: 630px) {
    grid-template-columns: 100%;
  }
}
