.db-container {
    padding: 0 50px;
    padding-top: 200px;
    @media screen and (max-width: 768px) {
        padding: 0 14px;
        padding-top: 150px;
    }
}

.dashboard{
    grid-column-gap: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .bottom{
        grid-column: 1/3;
        grid-column-gap: 20px;
    }
    @media screen and (max-width: 768px) {
        display: block;
    }
}