.heroImage {
  background: linear-gradient(
      0deg,
      rgba(52, 52, 51, 0.54),
      rgba(52, 52, 51, 0.54)
    ),
    url("../../../assets/images/heroImage.jpg");
  background-size: cover;
  padding-top: 150px;
  .holder {
    @media screen and (max-width: 768px) {
      padding: 0px;
    }
  }
}

.introGrid {
  display: grid;
  align-self: center;
  grid-template-columns: auto;
  justify-items: center;
  row-gap: 40px;
  @media screen and (max-width: 768px) {
    row-gap: 10px;
  }
  .top {
    width: 90%;
    border-bottom: solid 1px white;
    @media screen and (max-width: 768px) {
      h2 {
        font-size: 16px;
      }
    }
    .whiteLogo {
      width: 400px;
      object-fit: contain;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .four {
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 10px;
    }
  }
}
.lpcLogo {
  height: 200px;
  width: 200px;
  object-fit: contain;
  margin: 30px auto;
}

.lpcLogoSml {
  height: 100px;
  width: 100px;
  object-fit: contain;
  margin: 30px 10px 30px 0px;
}

.introCard {
  display: flex;
  text-align: center;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
  align-items: center;
  @media screen and (max-width: 768px) {
    border-bottom: solid 1px white !important;
    align-items: center;
    display: grid;
    grid-template-columns: 30% 65%;
    justify-content: space-between;
    padding: 0;
    text-align: left;
    padding-left: 2.5%;
    max-width: 500px;
  }
  &.right {
    border-right: solid 1px white;
    @media screen and (max-width: 768px) {
      border: none;
    }
    &.join {
      border-right: solid 1px $primaryColor;
    }
  }
  .introIcon {
    height: 70px;
    object-fit: contain;
    @media screen and (max-width: 768px) {
      grid-row: 1 / span 2;
    }
  }
  .introText {
    width: 80%;
    font-weight: 300;
    @media screen and (max-width: 768px) {
      height: 90px;
      grid-column: 2;
      width: 95%;
      vertical-align: middle;
    }
  }
  .introHeading {
    font-weight: 500;
    @media screen and (max-width: 768px) {
      white-space: wrap;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
    }
  }
}

.commonRoomSection {
  background-color: $primaryColor;
  padding-top: 30px;
  padding-bottom: 30px;
  .one {
    row-gap: 20px;
    @media screen and (max-width: 768px) {
      .four {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .top {
      width: 100%;
      border-bottom: solid 1px white;
      padding-bottom: 20px;
      h1,
      h3 {
        margin: 0px;
        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
      }
      h3 {
        font-size: 20px;
        font-weight: 500;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
  .commonRoomTile {
    display: flex;
    flex-direction: column;
    width: 100%;
    &.right {
      border-right: solid 1px white;
      @media screen and (max-width: 768px) {
        border-bottom: solid 1px white;
        border-right: 0;
      }
    }
    .contentUnit {
      font-size: 60px;
      font-weight: 500;
      margin: 0 20px 0 0;
    }
    .commonRoomIcon {
      height: 50px;
      object-fit: contain;
    }
    .contentText {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .contentDetail {
      font-weight: 500;
      margin-bottom: 20px;
    }
  }
}

.lectures {
  .backgroundImage {
    background: url("../../../assets/images/lecturesBg.jpg");
    background-size: cover;
  }
  .lecturesTable {
    background-color: rgba(255, 255, 255, 0.74);
    padding: 20px;
    width: 90%;
    margin: 0 auto;
    h1 {
      font-weight: 700;
    }
    .lectureContent {
      width: 60%;
      margin: 0 auto;
      font-size: 14px;
      font-weight: 500;
      line-height: 2;
    }
  }
  .homepageTableHolder {
    width: 75%;
    margin-bottom: 30px;
  }
}

.blogs {
  p {
    margin-bottom: 10px;
  }
  .two {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: 1366px) {
      min-height: 1000px;
    }
    @media screen and (min-width: 1024px) {
      min-height: 1200px;
    }
  }
  .underline {
    border-bottom: solid 1px $secondaryColor;
    padding-bottom: 20px;
    margin: 0;
  }
  .blogContent {
    font-size: 14px;
  }
  .blogTop {
    grid-template-columns: 50% 40%;
    padding-bottom: 30px;
    &::after {
      content: "";
      width: 200%;
      border-bottom: 1px solid $secondaryColor;
      padding-top: 10px;
    }
    .blogTopImg {
      display: flex;
      align-items: center;
    }
    .blogThumbnailLarge {
      width: 90%;
      height: 90%;
      object-fit: contain;
    }
    .headingOne {
      font-size: 26px;
      margin: 0;
      margin-bottom: 10px;
    }
    .headingTwo {
      font-weight: 400;
      margin: 10px 0 10px 0;
      font-size: 16px;
    }
  }

  .blogBottom {
    width: 100%;
    display: flex;
    max-height: 1000px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
    flex-wrap: wrap;
    .blogThumbnailSmall {
      width: 100%;
      height: 200px;
      object-fit: contain;
    }
    .bottomBlogHeading {
      font-weight: 600;
      font-size: 16px;
      margin: 10px 0 10px 0;
    }
  }
  .blogBottom > div {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
  .blogLeft {
    width: calc(45% - 1px);
    border-right: solid 1px $secondaryColor;
    //adding-right: 50px;
    padding: 2.5%;
  }
  .blogRight {
    width: 45%;
    padding: 2.5%;
  }
}

.jc-sb-c {
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .introHeading {
    white-space: wrap;
  }

  .lectures {

  }

  .lectures .holder {
    grid-template-columns: 100%;
  }

    .lectures .lecturesTable {
    font-size: 10px;
    padding: 0;
    padding: 0 12px;
    width: calc(100% - 24px);
  }

  .topicContent > p {
    font-size: 12px !important;
    padding: 0 2px;
  }

  .lectures .homepageTableHolder {
    width: 100%;
  }

  .jc-sb-c {
    justify-content: center;
    margin-bottom: 10px;
  }
}

.feeds-mobile {
  @media screen and (min-width: 1024px) {
    display: none;
  }
  &:nth-child(3) {
    @media screen and (max-width: 768px) {
      min-height: 200px !important;
    }
    @media screen and (max-width: 360px) {
      min-height: 240px !important;
    }
  }
}

.feeds-mobile-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100px;
  .blogContentMobile {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    min-height: 200px;
  }
  @media screen and (max-width: 360px) {
    min-height: 300px;
  }
}

.feed-mobile-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
    > button {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAYAAACGVs+MAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAIKADAAQAAAABAAAAHwAAAACsP2+eAAABeUlEQVRIDcWVPUsDQRCGb0UQG9t0dilMabC0SCMogpWN6fyCdKks9Ef4CxS0tLTQxj9gY2NnY2VhZRQLQfR8JhhYjtm77OY2uzDc3szt+7y77CRZFjjyPF8knggZ+4EyYcsANokXIVujF6YWsAroswW2pwcBcv5LbKIyP/RX9FwB9EQB26n4JwGtbxML81/edz335f+5QAiBaWNqJrahf2sOyE3NxDqwr9QmVjHwmdpEGwNvqU20MPA6rgnDhwvc/y7R8O8D54omlR1nNcv2jDHnwzoGHh1uY6eHvxNyAnmJ09ilzZnYhAr9jZQn8IG5NTmBywqXMcoDRDtcxHsj6lyDJR51dsEWen3RVobsXOAPSm3yFJvpEj+ENgYklyenOBQQ7xGuf8fo8GNty/+56PDTJHCg0sZnqeCzgK9SwecA3ySBSwMAvq6Atx2NUk+6BP5ObaUeSokKkFvFhLRa3J2PPAGaJ+4sE3H7fAQuPjFwRFwQrWJt3Pc/CXp9EegFpNAAAAAASUVORK5CYII=) center;
      background-repeat: no-repeat;
      object-fit: contain;
      background-size: 11px;
      display: block;
      height: 32px;
      width: 32px;
      margin-left: 10px;
      background-color: $primaryColor;
      border: 0;
      &:first-child {
        transform: rotate(180deg);
      }
      &:disabled {
        opacity: 0.6;
      }
    }
}

.mv-10-0 {
  margin: 10px 0;
  @media screen and (max-width: 580px) {
    margin: 0;
  }
}

.mv-0-10 {
  margin: 0;
  @media screen and (max-width: 580px) {
    margin: 10px 0;
    margin-bottom: 30px;
  }
}

.resp-hide-1024 {
  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}
