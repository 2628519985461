.lectures{
    row-gap: 20px;
    // margin-top: 30px;
    
    .top{
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            flex-direction: column;
            text-align: center;
            h1{
                margin-bottom: 20px;
            }
        }
    }
    .currentLectures{
        grid-column-gap: 40px;
        grid-template-columns: repeat(3, auto);
        .verticalBreak{
            height: 100%;
            width: 1px;
            background-color: $primaryColor;
        }
        .card{
            grid-template-columns: 35% 65%;
            position: relative;
            @media screen and (max-width: 768px) {
                grid-template-columns: repeat(1, auto);
            }
            
            p{
                font-size: 16px;
            }
            .profilePicture{
                height: 100px;
                width: 100px;
                border-radius: 50px;
                object-fit: cover;
                margin-left: 10px;
                box-shadow: 0px 3px 6px 0px rgba(0,0,0, 0.16);
            }
            .cardTop{
                grid-column: 1/3;
            }
            .cardBottom{
                grid-column: 1/3;
                h3{
                    margin-top: 5px;
                }
            }
        }
    }
}

.upcoming-lectures {
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, auto);
    }
    h4{
        margin-top: 3px;
    }
  
    .image{
        display: flex;
        align-items: center;
    }
    .card {
      display: flex;
      position: relative;
      @media screen and (max-width: 768px) {
          flex-direction: column;
          text-align: center;
          justify-content: center;
          align-items: center;
      }
      margin: 10px;
    }

    .profilePicture {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        margin-left: 20px;
        @media screen and (max-width: 768px) {
            margin: 0;
            margin-top: 20px;
        }
    }
 }

 .upcoming-lectures {
    -webkit-overflow-scrolling: touch;
  }

  .upcoming-lectures {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .lectureJoinBtn {
      text-decoration: none;
  }