.commonRooms{
    grid-template-columns: 100%;
    padding: 0px;
    justify-content: center;
    grid-row-gap: 20px;
    .commonRoomsLeft{  
        padding: 150px 20px 0px 50px;
        @media screen and (max-width: 1024px) {
            padding:100px 20px 0 20px;
        }

    }
    // .commonRoomsRight{
    //     background-color: $primaryColor;
    //     padding: 150px 50px 0px 50px;
    //     overflow: scroll;
    //     h1{
    //         white-space: nowrap;
    //     }
    // }
    .roomsGrid{
        grid-column-gap: 40px;
        @media screen and (max-width: 1024px) {
            grid-template-columns: repeat(2, auto);
        }
        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(1, auto);
        }
    }
}

.profilePic {
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.meetingRequestContainer {
    display: flex;
    flex-direction: column;
}

.ProfilePicContainer {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.meetingFormContainer {
    display: flex;
    flex-direction: column;
    width: 30vw;
    margin: 10px;
    margin-top: 0px;
}

.meetingContentContainer {
    display: flex;
    flex-direction: row;
}

.meetingRequestContainerSuccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}