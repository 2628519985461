.aboutUs{
    &.holder{
        @media screen and (max-width: 1250px){
            width: 100%;
        }
        @media screen and (max-width: 800px){
            text-align: center;
        }
    }
}


.aboutUsGridOuter{
    .gridBreak{
        height: 1px;
        width: 100%;
        background-color: $primaryColor;
        margin-bottom: 30px;
        @media screen and (max-width: 800px){
            display: none
        }
    }
}
.aboutUsGrid{
    margin: 30px 0 30px 0;
    @media screen and (max-width: 800px){
        grid-template-columns: auto;
        margin: 0;
    }
    .aboutUsContent{
        padding: 0px 40px 15px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 800px){
            padding: 0;
            align-items: center;
            margin-bottom: 40px;
        }
        &:nth-child(2){
                padding: 0px 0px 15px 40px;
                border-left: solid 1px $primaryColor;
                @media screen and (max-width: 800px){
                    padding: 0;
                    border-left: 0;
                }
            }
        .aboutUsContentTop{
            display: flex;
            align-items: center;
            @media screen and (max-width: 800px){
                flex-direction: column;
            }
            h1{
                margin: 0;
                @media screen and (max-width: 800px){
                    order: 2;
                }
            }
            .aboutUsContentIcon{
                height: 45px;
                object-fit: contain;
                margin-left: 30px;
                @media screen and (max-width: 800px){
                    order: 1;
                    margin: 0px 0px 20px 0px;
                }
            }
        }
        .aboutUsContentText{
            font-size: 15px;
            margin-top: 20px;
        }
    }
}
