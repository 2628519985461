.footer{
    background-color: $primaryColor;
    position:absolute;
    left:0;
    bottom:0;
    right:0;
    h3{
        margin-bottom: 15px;
    }
    .holder{
        padding: 0px!important;
    }
    .footerHeader{
        font-size: 15px;
        margin-bottom: 30px;
    }
    .footerGrid{
        .footerTop{
            grid-template-columns: 35% 65%;
            justify-content: space-between;
            border-bottom: solid .5px white;
            padding-top: 20px;
            .two{
                grid-template-columns: 55% 45%;
            }
            @media screen and (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }
            @media screen and (max-width: 568px) {
                .two{
                    grid-template-columns: repeat(1, 1fr);
                }
            }
            .footerLinks{
                .linkList{
                    list-style: none;
                    column-count: 1;
                    padding: 0;
                    margin-bottom: 0px;
                    .linkItem{
                        font-size: 13px;
                        font-weight: 300;
                        width: max-content;
                        margin-bottom: 20px;
                    }
                }
            }
            .footerContact{
                .contactList{
                    list-style: none;
                    padding: 0;
                    margin-bottom: 0px;
                    .contactListItem{
                        font-size: 13px;
                        font-weight: 300;
                        margin-bottom: 10px;
                        text-decoration: none;
                        .footerContactIcon{
                            width: 10px;
                            object-fit: contain;
                            margin-right: 10px;
                        }
                    }
                }
                .footerInput{
                    width: 80%;
                    padding: 10px;
                    font-size: 16px;
                    color: $primaryColor;
                    background-color: lightgrey;
                    border: none;
                    ::placeholder{
                        color: $primaryColor;
                    }
                }
                .footerLogo{
                    width: 90%;
                    object-fit: contain;
                    @media screen and (max-width: 568px) {
                        width: 60%;
                        margin-bottom: 15px;
                }
            }
                .right{
                    padding-top: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    p{
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 1.5;
                        width: 90%;
                        text-align: center;
                        margin-top: 20px;
                    }
                    @media screen and (max-width: 568px) {
                        align-items: center;
                    }
                }
            }
        }
        .footerBottom{
            display: flex;
            padding: 20px 0 20px 0;
            justify-content: space-between;
            align-items: center;
            .footerSocial{
                height: 25px;
                object-fit: contain;
                margin-left: 15px;
            }
        }
    }
}

.popia {
    width: 90%;
    text-align: center;
    padding-top: 20px;
}

.popiaFC {
    width: 100%;
    color: #fff;
    text-align: left;
}