body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast {
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
}
.Toastify__toast--success {
  background-color: white !important;
  text-align: center !important;
  color: #001C38;
  margin: 50px;
  padding: 3em !important;
  width: 350px !important;
}
.Toastify__toast--warning {
  background-color: white;
}
.Toastify__toast--error {
  background-color: white !important;
  text-align: center !important;
  color: red;
  margin: 50px;
  padding: 3em !important;
  width: 350px !important;
}
.Toastify__toast-body {
}

.multi_select {
  color: black !important;
  background-color: transparent !important;
    border: 1px solid #2A4E60 !important;
    padding: 10px 5px !important;
    font-size: 16px !important;
    margin-bottom: 10px !important;
}

.date_input {
  border: 1px solid #2A4E60 !important;
  padding: 2px 2px;
  font-size: 16px !important;
  margin-bottom: 10px !important;
  background-color: transparent !important;
  padding: 20px;
}

.textarea {
  background-color: transparent !important;
  border: 1px solid #2A4E60 !important;
  padding: 10px 5px !important;
  font-size: 16px !important;
  margin-bottom: 10px !important;
  min-height: 50px !important;
}

::-webkit-datetime-edit {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}