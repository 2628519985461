.contactUs{
    .top{
        border-bottom: solid 1px $primaryColor;
        .left{
            div{
                margin-bottom: 20px;
                img{
                    width: 30px;
                    object-fit: contain;
                    margin-right: 30px;
                }
                p{
                    font-size: 24px;
                }
            }
        }
    }
}

.blogDate{
    margin-top: 0px;
    color: lightgrey;
}

.blogThumbnailLarge{
    width: 400px;
    margin-bottom: 20px;
    margin-right: 20px;
    float: left;
}