@import './assets/defaultStyles.scss';
@import './components/enhancedComponents/index.scss';
@import './components/basicComponents/index.scss';
@import './containers/index.scss';

html {
    width: 100%;
    overflow-x: hidden;
}

body {
    overflow: hidden;
    width: 100%;
}

.p-50-20-10 {
    padding: 0 150px;
    @media screen and (max-width: 768px) {
        padding: 0 20px;
    }
    @media screen and (max-width: 540px) {
        padding: 0 10px;
    }
}

// @media only screen and (max-width: 1024px) {
//     body {
//         padding-bottom: 0;
//     }

//     #root {
//       overflow: hidden;
//       height: 100vh !important;
//       padding-bottom: 0;
//     }

//     .mobile-mode {
//         display: block !important;
//     }
// }

// .mobile-mode {
//     display: none;
// }
