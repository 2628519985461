.whyJoin {
    padding-top: 180px !important;
    h1 {
        text-align: left;
    }
    @media screen and (max-width: 1024px) {
        padding-top: 120px !important;
    }
}

.whyJoinGrid{
    row-gap: 30px;
    .iframe{
        @media screen and (max-width: 1024px){
            width: 100%;

        }
    }
    .content{
        grid-template-columns: 70% 30%;
        @media screen and (max-width: 1024px){
            grid-template-columns: repeat(1, auto);
        }
        .whyJoinIcon{
            height: 55px;
            object-fit: contain;
        }
        align-items: center;
        &:nth-child(2){
            direction: rtl;
            text-align: left;
            @media screen and (max-width: 1024px){
                text-align: left;
            }
            .contentLeft{
                padding: 0px 70px 0px 0px;
                @media screen and (max-width: 1024px){
                    padding: 0px
                }
            }
            .button{
                display: block !important;
                direction: ltr;
                margin-top: 30px;
            }
        }
        .contentLeft{
            padding-top: 0;
            padding-right: 70px;
            @media screen and (max-width: 1024px){
                padding: 0px
            }
            .item{
                margin-bottom: 40px;
            .two{
                display: grid;
                grid-template-columns: 10% 70%;
                @media screen and (max-width: 1024px){
                    grid-template-columns: repeat(1, auto);
                    justify-content: center;
                    justify-items: center;
                    text-align: center;
                }
                align-items: center;
                margin-bottom: 10px;
            }
            .button{
                display: none;
            }
        }
        }
        .contentRight{
            @media screen and (max-width: 1024px){
                justify-content: center;
                justify-self: center;
                align-items: center;
                align-content: center;
            }
            .contentImage{
                max-height: 600px ;
                max-width: 400px;
                object-fit: contain;
                @media screen and (max-width: 400px){
                    max-height: 500px ;
                max-width: 300px;
                }
            }
        }
        ul{
            padding-left: 1em;
            font-size: 14px;
            @media screen and (max-width: 1024px){
                text-align: left;
            }
        }
    }
}