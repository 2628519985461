.whyJoin{
    @media screen and (max-width: 380px) {
        font-size: 12px;
    }
    h1{
        @media screen and (max-width: 900px){
            text-align: center;
        }
    }
    .whyJoinGrid{
        row-gap: 30px;
        .content{
            align-items: center;
            @media screen and (max-width: 900px){
                grid-template-columns: repeat(1, 1fr);
            }
            .contentHeading{
                margin-bottom: 0px;
                @media screen and (max-width: 900px){
                    text-align: center;
                }
            }
            &:nth-child(2){
                direction: rtl;
                text-align: left;
                @media screen and (max-width: 900px){
                    text-align: center;
                    direction: unset;
                }
                .contentLeft{
                    padding: 0px 70px 0px 0px;
                    @media screen and (max-width: 900px){
                        padding: 0px;
                    }
                }
                .button{
                    display: block !important;
                    direction: ltr;
                    margin-top: 30px;
                }
            }
            .contentLeft{
                padding-top: 0;
                padding-right: 70px;
                @media screen and (max-width: 900px){
                    padding: 0px;
                }
                .button{
                    display: none;
                }
            }
            .contentRight{
                .contentImage{
                    max-height: 380px ;
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                    @media screen and (max-width: 900px){
                        width: 100%;
                        object-fit:fill
                    }
                }
            }
        }
    }
}